'use client'

import React from 'react'
import usePageProps from '../hooks/usePageProps'
import FooterMobile from './FooterMobile'
import FooterDesktop from './FooterDesktop'

const FooterWithContent = ({ content }) => {
    const { data } = usePageProps()
    const finalContent = content || data.footerStory

    if (!finalContent) {
        return null
    }

    return (
        <>
            <FooterMobile content={finalContent} />
            <FooterDesktop content={finalContent} />
        </>
    )
}

export default FooterWithContent
