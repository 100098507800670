'use client'

import React from 'react'
import usePageProps from '../hooks/usePageProps'
import HeaderMobile from './HeaderMobile'
import HeaderDesktop from './HeaderDesktop'

const HeaderWithContent = ({ content }) => {
    const { data } = usePageProps()
    const finalContent = content || data.headerStory

    if (!finalContent) {
        return null
    }

    return (
        <header className="h-full">
            <HeaderMobile content={finalContent} />
            <HeaderDesktop content={finalContent} />
        </header>
    )
}

export default HeaderWithContent
